import style from "./SecondBlock.module.css";
import second_block from "../../../../assets/second_block.svg";
import { useInView } from 'react-intersection-observer';

const SecondBlock = (props) => {

  const{ ref,inView } = useInView({
    threshold:0,
  })

  const content = [
    {
      title: "10",
      price: "350 zł",
    },
    {
      title: "30",
      price: "450 zł",
    },
    {
      title: "70",
      price: "550 zł",
    },
    {
      title: "100",
      price: "650 zł",
    },
    {
      title: "150",
      price: "850 zł",
    },
    {
      title: "200",
      price: "1050 zł",
    },
    {
      title: "300",
      price: "1300 zł",
    },
    {
      title: "400",
      price: "1550 zł",
    },
  ];
  return (
    <div className={style["block"]}>
      <div className={style["content-block"]}>
        <img src={second_block} alt="" />
        <div className={style["content"]}>
          <span className={style["title"]}>
            <div>
            {props.t("price_second_title")}
            </div>
            <div>
            {props.t("price_second_title2")}
            </div>
          </span>
          <div className={style["item-block"]}>
            {content.map((el) => (
              <div  ref = {ref} className={`${style["item"]} ${inView ? style["show-element"]: style[""]}`} key={el["title"]}>
                <span className={style["item__title"]}>
                  {props.t("price_item_first") +
                    el["title"] +
                    props.t("price_item_second")}
                </span>
                <span className={style["item__price"]}>{el["price"]}</span>
              </div>
            ))}
          </div>
          <span className={style["description"]}>
            {props.t("price_description_first")}
            <span>5 zł</span>
            {props.t("price_description_second")}
          </span>
          <button onClick={props.setContactFormState}>
            {props.t("price_block_contact_button")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SecondBlock;
