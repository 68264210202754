
import planet from "../../assets/planet.svg";
import { Link, useLocation, useMatch } from 'react-router-dom';
import { useState, useEffect } from "react";
import useOutsideAlerter from "./useOutsideAlerter";
import { generateLanguage} from '../../i18n';
import { whitelists } from "../../i18n";
import style from "../languageSelector/LanguageSelector.module.css"
const LanguageSelector = (props) => {
  //const [currentLanguage, setCurrentLanguage] = useState('RU');
  const [ref,isShow,setIsShow] = useOutsideAlerter(false);

  const location = useLocation();
  const match = useMatch('/:lang/*');


  let lang = match && match.params ? 
  (whitelists.includes(match.params.lang) ? match.params.lang : null) : null ;

  const disolayLang = lang ? lang : 'ru';

  const [currentLanguage, setCurrentLanguage] = useState(disolayLang.toLocaleUpperCase());

  const setLanguage = (language) => {
    setCurrentLanguage(language.toUpperCase());
    props.onLanguageChange(language.toLowerCase());
    setIsShow(false);
  }

 
  const langData = ['PL', 'EN', 'UA', 'RU'];
  const avalibleOption = langData
  .filter(item => item !== currentLanguage)
  .map(item => (
    <Link className={style["dropdown-item"]} key={item} to={generateLanguage(item.toLocaleLowerCase(), location)} onClick={()=>{
      setLanguage(item);
      }}>
        <img className={style['languageSelectorImage']} src={planet} alt="" />
        <div className={style["lang-item-label"]} >{item}</div>
    </Link>
))
  return (
    <div ref ={ref} className={style["dropdown"]}>
        <div className={style["dropdown-btn"]} onClick={() => {
          setIsShow(!isShow)
          }}>
            <img className={style['languageSelectorImage-main']} src={planet} alt="" />
          
          <div className={style["lang-itam-label"]}>{currentLanguage}</div>
        </div>
        {isShow && (
        <div className={style["dropdown-content"]} style={{ width: props.width }}>
          {avalibleOption}
        </div>)}
    </div>
  );
};

export default LanguageSelector;