import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Routes, Route } from "react-router-dom";
import "./App.css";
import Page404 from "./components/404/404";
import Body from "./components/Body/Body";
import ContactForm from "./components/ContactForm/ContactForm";
import Sucess from "./components/ContactForm/Success";
import Footer from "./components/Footer/Footer";
import Header from "./components/Header/Header";
import MobileMenu from "./components/MobileMenu/MobileMenu";
import PrivacyPolicyPopUp from "./components/Pop-Up/PrivacyPolicyPopUp";
import PrivacyPolicy from "./components/PrivacyPolicy/PrivacyPolicy";
import "./i18n.js";
import MainPopUp from "./components/Pop-Up/main-auto-popup/MainPopUp";
import Blog from "./components/blog/Blog";
import BlogFullVersion from "./components/blog/BlogFullVersion";
import { Helmet, HelmetProvider } from 'react-helmet-async';

const Layout = (props) => {
  return (
    <React.Fragment>
      <Header
        t={props.t}
        changeLanguages={props.changeLanguages}
        setMobileMenuState={props.setMobileMenuState}
      />
      <Outlet />
      <Footer t={props.t} changeLanguages={props.changeLanguages} />
    </React.Fragment>
  );
};

const App = () => { //app
  const { t, i18n } = useTranslation();

  const changeLanguages = (language) => {
    i18n.changeLanguage(language);
  };

  useEffect(() => {
    localStorage.getItem("p_up_state") === "true"
      ? setPopUpState(false)
      : setPopUpState(true);
  }, []);
  const [popUpState, setPopUpState] = useState(true);
  const [contactFormState, setContactFormState] = useState(false);
  const [contactSuccessSend, setContactSuccessSend] = useState(false);

  /*  auto popUp*/

  const [lang, setLang] = useState(i18n.language)

  const [showMainPopUp, setShowMainPopup] = useState(true);
  const [mainPopUp, setMainPopUp] = useState(false);

  const setMainPopUpHandler = () => {
    setMainPopUp((prev) => !prev)
  };

 useEffect(() => {
    if (showMainPopUp === true && lang !== 'pl') {
      setTimeout(() => setMainPopUp(true), 10000);
      setShowMainPopup(false);
    }
  }, [showMainPopUp, lang]);

  const setContactFormStateHandler = () => {
    setContactFormState((prev) => !prev);
  };

  const onClickPopUpAgreeButton = () => {
    localStorage.setItem("p_up_state", "true");
    setPopUpState(false);
  };

  const [mobileMenuState, setMobileMenuState] = useState(false);

  const setMobileMenuStateHandler = (event) => {
    event.preventDefault();
    setMobileMenuState((prev) => {
      prev = !prev;
      return prev;
    });
  };
  return (
    <HelmetProvider>
      <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t("head_title")}</title>
        <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
        {mobileMenuState && (
          <MobileMenu
            t={t}
            changeLanguages={changeLanguages}
            setMobileMenuState={setMobileMenuStateHandler}
          />
        )}
        <Routes>
          <Route
            path="/:lang?"
            element={
              <React.Fragment>
                {mainPopUp && (<MainPopUp t={t} setMainPopUpHandler={setMainPopUpHandler} setContactFormState={setContactFormStateHandler} />)}
                {popUpState && (<PrivacyPolicyPopUp t={t} onClickPopUpAgreeButton={onClickPopUpAgreeButton} />)}
                {contactFormState && (<ContactForm t={t} setContactFormState={setContactFormStateHandler} />)}
                {contactSuccessSend && (<Sucess t={t} setContactFormState={setContactSuccessSend} />)}
                <Layout t={t} changeLanguages={changeLanguages} setPopUpState={setPopUpState} setMobileMenuState={setMobileMenuStateHandler} />
              </React.Fragment>
            }>
            <Route index element={<Body t={t} setContactFormState={setContactFormStateHandler} onSendForm={setContactSuccessSend} />} />
            <Route path="blog" element={<Blog t={t} />} />
            <Route path='post/:id' element={< BlogFullVersion t={t} />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
          </Route>
          <Route path="*" element={<Page404 t={t} />} />
        </Routes>
      </div>
    </HelmetProvider>

  );
};

export default App;
