
import React from 'react';
import './Blog.scss';
import imageUrlBuilder from "@sanity/image-url";
import { useNavigate, useMatch, useLocation, useParams } from 'react-router-dom';
import { useState, useEffect } from 'react';
import Slider from 'react-slick';
import BlogImg from '../../assets/blog-image.svg';
import { whitelists } from '../../i18n';

function Blog({ t }) {

  const match = useMatch('/:lang/*');
  let lang = match && match.params ? (whitelists.includes(match.params.lang) ? match.params.lang : null) : null;
  const postLink = lang ? `/${lang}/post/` : `/post/`;
  const navigate = useNavigate();
  const handleClickPostFull = (id) => {
    navigate(`${postLink}${id}`);
  };

  const [postData, setPostData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      const PROJECT_ID = "viezo7im";
      const DATASET = "production";
      const QUERY = encodeURIComponent(
        `*[_type == "post"]{_id,title,shortText,_updatedAt,mainImage, author->, body[],  "image": image{...,"asset": asset->,}}`
      );
      const PROJECT_URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;
      try {
        const response = await fetch(PROJECT_URL);
        const { result } = await response.json();
        setPostData(result);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const builder = imageUrlBuilder({
    projectId: "viezo7im",
    dataset: "production",
  });

  function formatDate(dateStr) {
    const inputDate = new Date(dateStr);
    /* const monthNames = [
       "January", "February", "March", "April", "May", "June", "July",
       "August", "September", "October", "November", "December"
     ];*/
    const month = inputDate.getMonth();
    const day = inputDate.getDate();
    const year = inputDate.getFullYear();
    return `${day}. ${month}. ${year}`;
  }

  const blogShortItem = postData && postData.map((item) => {
    const title = item.title ? (lang == null ? item.title.ru : item.title[lang]) : "";
    const shortText = item.shortText ? (lang == null ? item.shortText.ru : item.shortText[lang]) : "";

    return (
      <div className='blog-main-item-wrapper' key={item._id}>
        <div className="blog-content">
          <div>
            {item.mainImage && item.mainImage.asset && (
              <div>
                <img
                  src={builder.image(item.mainImage.asset._ref).url()}
                  alt={item.author}
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                />
              </div>
            )}
            <div className="blog-text-content">
              <div className="blog-date" >{formatDate(item._updatedAt.split("T")[0])}</div>
              <div className="blog-text-title" >{title}</div>
              <div className="blog-text-description" >{shortText}</div>
            </div>
            <div className="blog-button">
              <button onClick={() => { handleClickPostFull(item._id); }}>{t("blog_card_button")}</button>

            </div>
          </div>
        </div>
      </div>
    );
  });

  let itemLength = blogShortItem.length;
  console.log(itemLength)

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    slidesToScroll: itemLength === 1 ? 1 : (itemLength === 2 ? 1 : 3),
    rows: (itemLength % 6 === 0) ? 2 : 1,
    responsive: [
      {
        breakpoint: 1285,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
          rows: 1
        }
      },
      {
        breakpoint: 1255,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 1025,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true
        }
      },
      {
        breakpoint: 880,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="blog-wrapper">
      <div className="blog-top">
        <div className="blog-top-content">
          <div className="blog-text-block">
            <div className="blog-capture">
              <span>{t("blog_capture1")}</span>
              <span className='blog-green'>{t("blog_capture2")}</span>
            </div>
            <div className="blog-description">
              {t("blog_description")}
            </div>
          </div>
          <div className="blog-main-image-block">
            <img src={BlogImg} alt="" />
          </div>
        </div>
      </div>
      <div className="blog-slider-block">
        {blogShortItem && (
          <Slider {...settings} >
            {blogShortItem}
          </Slider>
        )}
      </div>
    </div>
  );
}

export default Blog;