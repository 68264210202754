import React from 'react';
import './BlogFullVersion.scss';
import { Link } from 'react-router-dom';
import imageUrlBuilder from "@sanity/image-url";
import { useState, useEffect } from 'react';
import BlockContent from '@sanity/block-content-to-react';
import { useMatch, useParams } from 'react-router-dom';
import { whitelists } from '../../i18n';
import arrowBack from '../../assets/arrow-back.svg';
import BlogImg from '../../assets/blog-image.svg';


function BlogFullVersion({ t }) {

    const { id } = useParams();
    const [postData, setPostData] = useState(null);
    const builder = imageUrlBuilder({
        projectId: "viezo7im",
        dataset: "production",
    });

    const match = useMatch('/:lang/*');
    let lang = match && match.params ? (whitelists.includes(match.params.lang) ? match.params.lang : null) : null;
    const backLink = lang ? `/${lang}/blog` : `/blog`;

    useEffect(() => {
        const PROJECT_ID = "viezo7im";
        const DATASET = "production";
        const QUERY = encodeURIComponent(
            `*[_type == "post" && _id == "${id}"]{_id, title,shortText,_updatedAt, mainImage, author->, body[], "image": image{...,"asset": asset->,}}`
        );

        const PROJECT_URL = `https://${PROJECT_ID}.api.sanity.io/v2021-10-21/data/query/${DATASET}?query=${QUERY}`;

        fetch(PROJECT_URL)
            .then((res) => res.json())
            .then(({ result }) => {
                if (result.length > 0) {
                    setPostData(result[0]);
                }
            })
            .catch((err) => console.error(err));
    }, [id]);

    function formatDate(dateStr) {
        const inputDate = new Date(dateStr);
        const monthNames = [
            "January", "February", "March", "April", "May", "June", "July",
            "August", "September", "October", "November", "December"
        ];
        const month = monthNames[inputDate.getMonth()];
        const day = inputDate.getDate();
        const year = inputDate.getFullYear();
        return `${month} ${day}, ${year}`;
    };

    const serializers = {
        types: {
            code: (props) => (
                <pre data-language={props.node.language}>
                    <code>{props.node.code}</code>
                </pre>
            ),
            image: (props) => {
                if (props.node.asset) {
                    return (
                        <img
                            className="custom-image-style"
                            src={builder.image(props.node.asset).auto('format').url()}
                            alt={props.node.alt}
                        />
                    );
                } else {
                    return null;
                }
            },
            link: ({ mark, children }) => {
                const { href } = mark;
                const isExternalLink = href.startsWith('http://') || href.startsWith('https://') ;

                if (isExternalLink) {
                    return (
                        <Link to={`${href}`} target='_blank' onClick={(event) => {event.preventDefault(); window.open(href)}}>
                            {children}
                        </Link>
                    );
                } else {
                    console.log('no')
                }
            },
        },
    };

    let bodyContent= null;

    if (postData && postData.body) {
        bodyContent= postData.body.find((i) => i._type === lang && lang !== null);

        if (!bodyContent) {
            bodyContent= postData.body.find((i) => i._type === 'ru');
        }
    }

    function ColoredText(text) {
        if (!text) {
            return '';
        }
        const textParts = text.split(' ');
        const firstWord = textParts[0];
        const restOfText = textParts.slice(1).join(' ');

        const firstWordStyle = {
            color: '#000',
        };

        const restOfTextStyle = {
            color: '#34A573',
        };

        return (
            <div>
                <span style={firstWordStyle}>{firstWord}</span>
                <span> </span>
                <span style={restOfTextStyle}>{restOfText}</span>
            </div>
        );
    }

    return (
        <>
            <div className="blogFull-content-wrapper">
                {postData && (
                    <>
                        <div className="blog-full-top">
                            <div className="blog-full-top-content">
                                <div className="blogFull-text-block">
                                    <div className="blogFull-capture">
                                        {postData && postData.title && postData.title[lang] ? ColoredText(postData.title[lang]) : ColoredText(postData.title.ru)}
                                    </div>
                                    <div className="blogFull-description">
                                        {postData && postData.shortText && postData.shortText[lang] ? postData.shortText[lang] : postData.shortText.ru}
                                    </div>
                                </div>
                                <div className="blogFull-main-image-block">
                                    <img src={BlogImg} alt="blog img" />
                                </div>
                            </div>
                        </div>
                        <div className="blog-full-body-content">
                            <div className="blog-full-nav">
                                <Link to={backLink}>
                                    <div className="button-back">
                                        <div className='blog-arrow-position'><img src={arrowBack} alt="back" /></div>
                                        <div className='blog_full_back_button'>{t("blog_full_back_button")}</div>
                                    </div>
                                </Link>
                                <div className="date-of-creation">
                                    {formatDate(postData._updatedAt)}
                                </div>
                            </div>
                            <div className="blog-full-body-content-position">
                                {bodyContent&& (
                                    <BlockContent blocks={bodyContent.content} serializers={serializers} />
                                )}
                            </div>
                            {/* <div className="body-full-author">
                                {postData.author && (
                                    <div className='body-full-author-position'>                         if author needed
                                        <div>{postData.author.name}</div>
                                        {postData.author.image && (
                                            <img
                                                src={builder.image(postData.author.image.asset._ref).url()}
                                                alt={'image3'}
                                                style={{ maxWidth: "100px", maxHeight: "100px" }}
                                            />
                                        )}
                                    </div>
                                )}
                            </div>*/}
                        </div>
                    </>
                )}
            </div>
        </>
    );
}

export default BlogFullVersion;




