import style from "./Footer.module.css";
import finexp_logo from "../../assets/finexp_logo.svg";
//import { Link } from "react-scroll";
import { Link, useMatch } from "react-router-dom";
import logoInst from '../../assets/logoInst.svg';
import logoFb from '../../assets/logoFb.svg';
import logoLkdn from '../../assets/logoLkdn.svg';
import { HashLink } from 'react-router-hash-link';
import { whitelists } from "../../i18n";
import { useTranslation } from "react-i18next";
import ScrollToTop from "../scrollToTop/ScrollToTop";

const Footer = (props) => {

  setTimeout(function () {
    window.BinotelCallTracking[512006].replacePhoneNumbersOnDynamicContent();
  }, 500);

  const { t, i18n } = useTranslation();
  const match = useMatch('/:lang/*');
  let lang = match && match.params ?
    (whitelists.includes(match.params.lang) ? match.params.lang : null) :
    null;

  const homeLink = lang ? `/${lang}` : '/';

  const links = [
    {
      img: logoInst,
      link: 'https://www.instagram.com/finance.expert.pl/'
    },
    {
      img: logoFb,
      link: 'https://www.facebook.com/financeexpert.polska'
    },
    {
      img: logoLkdn,
      link: 'https://www.linkedin.com/company/financeexpertpl/'
    },

  ];

  const linkItem = links.map((item) => (
    <div key={item.link} className={style["social-link-item-content"]} >
      <div className={style["social-img"]} >
        <a href={item.link} target="blank">
          <img src={item.img} alt="" />
        </a>
      </div>
    </div>
  ));

  const navData = [
    {
      id: 'about-block',
      description: props.t("about_us_link")
    },
    {
      id: 'offer-block',
      description: props.t("offer_link")
    },
    {
      id: 'price-block',
      description: props.t("price_link")
    },
    {
      id: 'benefit-block',
      description: props.t("benefits_link")
    },
    {
      id: 'contact-block',
      description: props.t("contact_link")
    },
  ];

  const navItem = navData.map((item) => (
    <div key={item.id} className={style["nav-item"]}><HashLink to={`${lang ? `/${lang}/#${item.id}` : `/#${item.id}`}`} smooth>{item.description}</HashLink></div>
  ));

  return (
    <footer className={style["footer"]}>
      <div className={style["content-block"]}>
        <div className={style["content"]}>
          <div className={style["links-logo-block"]} >
            <Link to={homeLink}>
              <img className={style["logo"]} src={finexp_logo} alt="" />
            </Link>
            <div className={style["social-links-wrapper"]} >
              {linkItem}
            </div>
          </div>
          <div className={style["link-block"]}>
            {navItem}
            <Link
              className={style["link-block__item"]}
              to="blog"
            >
              {props.t("blog_link")}
            </Link>
          </div>
          <div>
            <div className={style["address-block"]}>
              <span>Finance Expert Sp. z o.o.</span>
              <span>ul. Kuźnicza 10, III piętro</span>
              <span>50-138 Wrocław</span>
              <span>NIP: 8971906842</span>
            </div>
            <div className={style["contact-block"]}>
              <a className="zphone" href="tel:+48718808807">+48 718 808 807</a>
              <a href="mailto:biuro@finexp.pl">biuro@finexp.pl</a>
            </div>
          </div>
        </div>
        <div className={style["separator"]} />
        <div className={style["bottom-block"]}>
          <Link to="/privacy-policy" >
            {props.t("privacy_policy_link")}
          </Link>
          <span>{props.t("all_rights_reserved")}</span>
        </div>
      </div>
      <ScrollToTop />
    </footer>
  );
};

export default Footer;
