import style from "./BenefitBlock.module.css";
import benefit_1 from "../../../assets/benefit_1.svg";
import benefit_2 from "../../../assets/benefit_2.svg";
import benefit_3 from "../../../assets/benefit_3.svg";
import benefit_4 from "../../../assets/benefit_4.svg";
import benefit_5 from "../../../assets/benefit_5.svg";
import benefit_6 from "../../../assets/benefit_6.svg";
import benefit_7 from "../../../assets/benefit_7.svg";
import benefit_8 from "../../../assets/benefit_8.svg";
import { useInView } from 'react-intersection-observer';

const BenefitBlock = (props) => {
  const { ref, inView } = useInView({
    threshold: 0.5,
  })
  const contentLeft = [
    {
      icon: benefit_1,
      title: props.t("benefits_item_first"),
    },
    {
      icon: benefit_3,
      title: props.t("benefits_item_second"),
    },
    {
      icon: benefit_5,
      title: props.t("benefits_item_third"),
    },
    {
      icon: benefit_7,
      title: props.t("benefits_item_fourth"),
    },
  ];
  const contentRight = [
    {
      icon: benefit_2,
      title: props.t("benefits_item_fifth"),
    },
    {
      icon: benefit_4,
      title: props.t("benefits_item_sixth"),
    },
    {
      icon: benefit_6,
      title: props.t("benefits_item_seventh"),
    },
    {
      icon: benefit_8,
      title: props.t("benefits_item_eighth"),
    },
  ];

  const contentLeftItem = contentLeft.map((item,index) => (
    <div key={index} className={style["item"]}>
      <div className={style["icon"]}>
        <img src={item.icon} alt="icon"/>
      </div>
      <div className={style["text"]}>
        {item.title}
      </div>
    </div>
  ));

  const contentRightItem = contentRight.map((item,index) => (
    <div key={index} className={style["item"]}>
      <div className={style["icon"]}>
        <img src={item.icon} alt="icon"/>
      </div>
      <div className={style["text"]}>
        {item.title}
      </div>
    </div>
  ))


  return (
    <div className={style["block"]} id="benefit-block">
      
      <div className={style["content"]}>
      <h3 className={style["title"]}>
        {props.t("benefits_block_title")} <span>Expert</span>
      </h3>
        <div className={style["benefits"]}>
          {contentLeftItem}
        </div>
        <div className={style["benefits"]}>
          {contentRightItem}
        </div>
      </div>
    </div>
  );
};

export default BenefitBlock;
